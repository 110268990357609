import React from "react"

// Libraries
import Lottie from "react-lottie"
import styled from "styled-components"

// Animation
import LoadingAnimation from "assets/animations/components/loading-animation.json"

const StyledLoadingCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  z-index: 9000;

  > div {
    width: 320px !important;
    height: 320px !important;

    svg {
      width: 100% !important;
      height: auto !important;
    }
  }
`

const LoadingCover = props => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <StyledLoadingCover visible={props.visible} className="bg--white">
      <Lottie options={animationOptions} isClickToPauseDisabled={true} />
    </StyledLoadingCover>
  )
}

export default LoadingCover
