import React from "react"

// Layout
import Layout from "layouts/layout-dashboard"

// Components
import Seo from "components/seo/"
import Container from "components/container/"

// Sections
import Deploys from "sections/dashboard/deploys"

const Dashboard = () => {
  return (
    <Layout>
      <Seo title="Dashboard - Indicius Design Agency" />
      <Container>
        <Deploys />
      </Container>
    </Layout>
  )
}

export default Dashboard
