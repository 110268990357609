import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

const StyledTable = styled.table`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;

  thead {
    width: 100%;
    position: sticky;
    top: 0;
    border-bottom: 1px solid ${colors.cloudy};

    th {
      ${breakpoint.medium`
        padding: 32px 24px;
      `}
    }
  }

  th,
  td {
    padding: 24px 16px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  tbody {
    tr {
      border-bottom: 1px solid ${colors.cloudy};
      border-opacity: 0.5;

      &:last-child {
        border: 0;
      }
    }
  }
`

const Table = props => (
  <div
    style={{
      width: "100%",
      overflowX: "auto",
      boxShadow: "0px 0 24px rgba(0, 0, 0, 0.05)",
    }}
  >
    <StyledTable className={props.className}>{props.children}</StyledTable>
  </div>
)

export default Table
