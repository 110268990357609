import React, { useState, useEffect } from "react"

// Libraries
import styled from "styled-components"
import moment from "moment"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import LoadingCover from "components/loading-cover/"
import Table from "components/table/"

const StyledDeploys = styled.section`
  padding: 80px 0;

  h1 {
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 60px;
    `}
  }

  .deploys__table {
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.05);

    .small {
      max-width: 300px;
      white-space: normal;
    }
  }

  .deploy__target {
    padding: 4px;
    border-radius: 4px;
  }

  .deploy__status {
    width: 8px;
    height: 8px;
    position: relative;
    top: -1px;
    display: inline-block;
    margin-right: 6px;
    line-height: 8px;
    border-radius: 50%;
  }

  a {
    transition: all 0.3s ease;
  }

  .deploys__load-more {
    margin-top: 40px;
    text-align: center;

    .button {
      display: inline-block;
      padding: 16px 32px;
      font-size: 18px;
      line-height: 24px;
      border-radius: 100px;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }
`

const Deploys = props => {
  const [deployments, setDeployments] = useState([])
  const [itemsToShow, setItemsToShow] = useState(5)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getDeployments = async () => {
      const url =
        process.env.NODE_ENV === "production"
          ? "https://indicius-api.vercel.app"
          : "http://localhost:3000"

      const { deployments: data } = await fetch(
        url +
          "/projects/QmYUWyWGuVJ2WLfnfYzE9Tu65SFLq5xQyoPZhs9nkdABaq/deployments?limit=" +
          itemsToShow
      ).then(response => response.json())

      setDeployments(data)
      setLoading(false)
    }

    getDeployments()
  }, [itemsToShow])

  const getDeploymentStatus = deployment => {
    if (!deployment.state) {
      return (
        <React.Fragment>
          <span className="deploy__status bg--cream"></span>
          Preparing
        </React.Fragment>
      )
    }

    switch (deployment.state) {
      case "BUILDING":
      case "DEPLOYING":
        return (
          <React.Fragment>
            <span className="deploy__status bg--dangerYellow"></span>
            Building
          </React.Fragment>
        )
      case "READY":
        return (
          <React.Fragment>
            <span className="deploy__status bg--successGreen"></span>
            Ready
          </React.Fragment>
        )
      case "ERROR":
        return (
          <React.Fragment>
            <span className="deploy__status bg--errorRed"></span>
            Error
          </React.Fragment>
        )

      default:
        return (
          <React.Fragment>
            <span className="deploy__status bg--cream"></span>
            Preparing
          </React.Fragment>
        )
    }
  }

  const getDateFromTimestamp = timestamp => {
    const now = moment()
    const createdAt = moment(timestamp)

    if (now.diff(createdAt, "hours") < 1) {
      return now.diff(createdAt, "minutes") + "m ago" // minutes
    } else if (now.diff(createdAt, "hours") < 24) {
      return now.diff(createdAt, "hours") + "h ago" // hours
    } else {
      return now.diff(createdAt, "days") + "d ago" // days
    }
  }

  const getDeployDuration = (start, end) => {
    start = moment(start)
    end = moment(end)

    return end.diff(start, "minutes") + "m"
  }

  return (
    <StyledDeploys>
      <LoadingCover visible={loading} />
      <h1>Deploys</h1>

      <Table className="deploys__table">
        <thead>
          <th>Deployment URL</th>
          <th>Target</th>
          <th>Message</th>
          <th>Status</th>
          <th>Created</th>
          <th>Duration</th>
        </thead>
        <tbody>
          {deployments &&
            deployments.map(deployment => (
              <tr key={deployment.uid}>
                <td>
                  {deployment.state === "READY" ? (
                    <React.Fragment>
                      <a
                        href={
                          deployment.target === "production"
                            ? "https://indicius.com"
                            : "https://preview.indicius.com"
                        }
                        className="color--indiblue color-hover--midnight"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {deployment.target === "production"
                          ? "indicius.com"
                          : "preview.indicius.com"}
                      </a>
                      <br />
                      <a
                        href={"https://" + deployment.url}
                        className="color--indiblue color-hover--midnight"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {deployment.url}
                      </a>
                    </React.Fragment>
                  ) : deployment.state === "ERROR" ? (
                    <span className="color--errorRed">Build failed</span>
                  ) : (
                    "Building..."
                  )}
                </td>
                <td>
                  {deployment.target === "production" ? (
                    <span className="deploy__target bg--indiblue color--midnight font-weight--700">
                      Production
                    </span>
                  ) : (
                    <span className="deploy__target bg--midnight color--white font-weight--700">
                      Staging
                    </span>
                  )}
                </td>
                <td className="small">
                  {deployment.meta.gitlabCommitMessage
                    ? deployment.meta.gitlabCommitMessage
                    : "New changes"}
                </td>
                <td>{getDeploymentStatus(deployment)}</td>
                <td>{getDateFromTimestamp(deployment.created)}</td>
                <td>
                  {deployment.ready &&
                    getDeployDuration(deployment.created, deployment.ready)}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="deploys__load-more">
        <button
          type="button"
          className="button bg--indiblue bg-hover--midnight color--white font-weight--500"
          onClick={() => {
            setItemsToShow(itemsToShow + 5)
          }}
        >
          Load More
        </button>
      </div>
    </StyledDeploys>
  )
}

export default Deploys
