import React from "react"

import "assets/globalStyles/reset.css"
import GlobalStyles from "assets/globalStyles/"
import "assets/globalStyles/animations.css"

class LayoutDashboard extends React.Component {
  render = props => (
    <React.Fragment>
      <GlobalStyles />
      {this.props.children}
    </React.Fragment>
  )
}

export default LayoutDashboard
